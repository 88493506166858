<template>
  <div>
    <div class="time-frame-container">
      <SelectMenu
        v-if="canChangeChartDateOrExport(roleLoggedUser)"
        :options="timeframeOptions"
        :selected="selectedTimeframe"
        @menuItemChanged="handleTimeframeSelection"
      />

      <button
        v-if="canChangeChartDateOrExport(roleLoggedUser)"
        class="button button--ghost"
        @click="showExportWaterFlow = true"
      >
        Export Data
      </button>
    </div>
    <div v-if="showExportWaterFlow">
      <ExportWaterFlow
        @close="closeModal"
        :sensorId="Number(this.$route.params.id)"
      />
    </div>
    <div class="chart-container">
      <canvas id="daily-water-flow"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import ExportWaterFlow from "./ExportWaterFlow.vue";
import SelectMenu from "../Shared/SelectMenu.vue";
import moment from "moment";
import { dailyWaterflowChartConfig } from "../../chart/chartConfig.js";

export default {
  data() {
    const timeframeOptions = {
      months_1: {
        value: "months_1",
        label: "Last Month",
      },
      months_3: {
        value: "months_3",
        label: "Last 3 Months",
      },
      months_6: {
        value: "months_6",
        label: "Last 6 Months",
      },
      months_12: {
        value: "months_12",
        label: "Last 12 Months",
      },
    };
    return {
      labels: [],
      waterFlowData: [],
      selectedTimeframe: timeframeOptions.months_12,
      movingAvgData: [],
      timeframeOptions,
      showExportWaterFlow: false,
      chart: null,
    };
  },
  components: {
    ExportWaterFlow,
    SelectMenu,
  },
  computed: {
    dailyWaterFlow() {
      return this.$store.state.detailWaterPoint.dailyWaterFlow;
    },
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
    selectedStartTimeFromNow() {
      let ret = moment();
      switch (this.selectedTimeframe.value) {
        case this.timeframeOptions.months_1.value:
          ret.subtract(1, "month");
          break;
        case this.timeframeOptions.months_3.value:
          ret.subtract(3, "month");
          break;
        case this.timeframeOptions.months_6.value:
          ret.subtract(6, "month");
          break;
        case this.timeframeOptions.months_12.value:
          ret.subtract(12, "month");
          break;
        default:
          break;
      }
      return ret;
    },
  },
  methods: {
    onTimeframeChanged(selectedItem) {
      this.selectedTimeframe = selectedItem;
    },
    closeModal() {
      this.showExportWaterFlow = false;
    },
    filterAndOrderChartData(timeFrame) {
      const dailyWaterFlowFiltered = this.dailyWaterFlow.filter((item) => {
        // only keep data within the given time frame
        return moment(item.dateDay) >= timeFrame;
      });
      // reverse data to be ordered from old to new
      const dailyWaterFlowReversed = dailyWaterFlowFiltered.slice().reverse();
      this.labels = dailyWaterFlowReversed.map((item) =>
        moment(item.dateDay).format("D MMM YYYY").toUpperCase(),
      );
      this.waterFlowData = dailyWaterFlowReversed.map((item) => item.liters);
      this.movingAvgData = dailyWaterFlowReversed.map((item) => item.movingAvg);
    },
    updateChart() {
      this.chart.data.datasets[0].data = this.waterFlowData;
      this.chart.data.datasets[1].data = this.movingAvgData;
      this.chart.data.labels = this.labels;

      // auto limit ticks if less than 2 months of data is showing
      if (this.labels.length >= 60) {
        this.chart.options.scales.xAxes[0].ticks.maxTicksLimit =
          this.labels.length;
      } else {
        this.chart.options.scales.xAxes[0].ticks.maxTicksLimit = 10;
      }
      this.chart.update();
    },
    handleTimeframeSelection(selectedItem) {
      this.selectedTimeframe = selectedItem;
      this.filterAndOrderChartData(this.selectedStartTimeFromNow);
      this.updateChart();
    },
    canChangeChartDateOrExport(role) {
      return role === "SuperAdmin" || role === "Admin" || role === "ViewEdit";
    },
  },
  async mounted() {
    if (!this.dailyWaterFlow) {
      // request for 12 month data if none found
      const dailyWaterFlowQueryParams = {
        ...this.$route.params,
        monthlyTimeframe: "12",
      };
      await this.$store.dispatch(
        "getSensorDailyWaterFlow",
        dailyWaterFlowQueryParams,
      );
    }
    this.filterAndOrderChartData(this.selectedStartTimeFromNow);
    this.chart = new Chart(
      document.getElementById("daily-water-flow").getContext("2d"),
      dailyWaterflowChartConfig,
    );
    this.updateChart();
  },
};
</script>

<style scoped lang="scss">
.time-frame-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  padding: 1em;
  &-button {
    background-color: #ffffff;
    border: 1px solid #003366;
    border-radius: 8px;
    padding: 10px 12px;
    color: #1d2433;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--app-font);
    &:hover {
      cursor: pointer;
      background-color: rgb(228, 228, 232);
    }
  }
  &-select {
    padding: 8px 10px;
    font-size: 14px;
    padding-right: 20px;
    border: 1px solid #e1e6ef;
    border-radius: 8px;
    background-color: white;
    font-family: var(--app-font);
    &:hover {
      cursor: pointer;
    }
  }
}
.chart-container {
  height: 65vh;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    max-width: 25em;
    height: 25em;
  }
  canvas {
    position: relative;
    padding: 1.75em;
  }
}
</style>
